<template>
  <NuxtImg
    v-if="imageObject.show"
    :src="imageObject.url"
    :alt="imageObject.alt"
    :title="imageObject.title"
    :height="imageHeight"
    :width="imageWidth"
    :sizes="useSizes"
    :format="useFormat"
    :fit="fit"
    :fetchpriority="imageFetchpriority"
    :loading="imageLoading"
    :preload="imagePreload"
    :quality="quality"
    :modifiers="modifiers"
    :placeholder="imagePlaceholder"
    placeholder-class="ui-img--with-placeholder"
    class="ui-img"
    :class="{ 'ui-img--loading': imagePlaceholder && !imageLoaded }"
    @animationend="handleImageLoaded"
  />
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import type { UiImgProps } from '~/types'

const imageLoaded = ref(false)
const handleImageLoaded = () => (imageLoaded.value = true)

const props = withDefaults(defineProps<UiImgProps>(), { height: 0, width: 0 })

const imageObject = computed(() => {
  const getImageExtension = (url: string | null | undefined) =>
    url ? url.match(/(?:\.([^.]+))?$/)?.[1] : ''

  if (typeof props.image === 'object' && props.image !== null) {
    const image = Array.isArray(props.image)
      ? (props.image?.[0] ?? {})
      : props.image

    return {
      show: Object.keys(image).length > 0,
      url: image?.publicUrl,
      alt: props.decorative ? '' : (image?.properties?.alternative ?? null),
      title: props.decorative ? null : (image?.properties?.title ?? null),
      extension:
        image?.properties?.extension ?? getImageExtension(image?.publicUrl),
      height: image?.properties?.dimensions?.height,
      width: image?.properties?.dimensions?.width
    }
  }

  return {
    show: !!props.image,
    url: props.image || '',
    alt: props.decorative ? '' : null,
    extension: getImageExtension(props.image)
  }
})

const isWrapperOnTop = computed(() => {
  return props.wrapperIndex === 0 || props.wrapperIndex === 1
})

const imageFetchpriority = computed(() => {
  return props.fetchpriority || (isWrapperOnTop.value ? 'high' : 'auto')
})

const imageLoading = computed(() => {
  return props.loading || (isWrapperOnTop.value ? 'eager' : 'lazy')
})

const imagePreload = computed(() => {
  return props.preload || isWrapperOnTop.value
})

const imageHeight = computed(() =>
  props.useImageSize
    ? (imageObject.value?.height ?? 'auto')
    : props.height || 'auto'
)

const imageWidth = computed(() =>
  props.useImageSize
    ? (imageObject.value?.width ?? 'auto')
    : props.width || 'auto'
)

const useSizes = computed(() => {
  if (!props.sizes) return ''

  return Object.entries(props.sizes)
    .map(([size, value]) => `${size}:${value}px`)
    .join(' ')
})

const useFormat = computed(() => {
  return (
    props.format || (imageObject.value?.extension === 'svg' ? 'svg' : undefined)
  )
})

const imagePlaceholder = computed(() => {
  if (useFormat.value === 'svg' || props.disablePlaceholder) return null
  return [imageWidth.value, imageHeight.value, 1, 1]
})

const modifiers = computed(() => {
  return {
    ...(props.useDevicePixelRatio ? { dpr: window?.devicePixelRatio || 1 } : {})
  }
})
</script>

<style lang="scss">
.ui-img {
  &--with-placeholder {
    filter: blur(4px);
    animation: imageLoading 1.2s infinite;
  }

  &--loading:not(&--with-placeholder) {
    animation: imageLoaded 1.2s;
  }
}

@keyframes imageLoading {
  0%,
  100% {
    filter: blur(4px);
  }

  50% {
    filter: blur(2px);
  }
}

@keyframes imageLoaded {
  0% {
    filter: blur(4px);
  }

  100% {
    filter: blur(0px);
  }
}
</style>
